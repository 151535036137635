.about-article {
  border-bottom: var(--border-style);
  &__container {
    display: flex;
    padding: 150px 0;
    @media (max-width: 992px) {
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
      padding: 50px 0;
    }
  }
  &__text {
    font-family: var(--default-font);
    line-height: 29px;
    &:not(:last-child) {
      margin-bottom: 30px;
      @media (max-width: 992px) {
        margin-bottom: 10px;
      }
    }
  }
  &__content {
    width: 41%;
    padding-right: 50px;
    @media (max-width: 992px) {
      width: 100%;
      padding-right: 0;
    }
    p {
      @media (max-width: 576px) {
        font-size: 12px;
      }
      &:not(:first-child) {
        @media (max-width: 992px) {
          display: none;
        }
      }
      &.text-show {
        @media (max-width: 992px) {
          display: block;
        }
      }
    }
  }
  &__read-more {
    display: none;
    font-size: 14px;
    &.button-read-more {
      padding: 0;
    }
    @media (max-width: 992px) {
      display: block;
    }
  }
  &__img {
    position: relative;
    width: 59%;
    img {
      max-width: 880px;
      width: 100%;
      height: 543px;
      object-fit: cover;
      @media (max-width: 576px) {
        height: 178px;
      }
    }
    .custom-button {
      position: absolute;
      bottom: -70px;
      left: -80px;
      @media (max-width: 992px) {
        left: 0;
      }
      @media (max-width: 576px) {
        padding: 30px;
        bottom: -50px;
      }
    }
    @media (max-width: 992px) {
      width: 100%;
      text-align: center;
      margin-bottom: 100px;
    }
    @media (max-width: 576px) {
      margin-bottom: 72px;
    }
  }
}
