.project-main {
  padding: 100px 0 150px 0;
  border-bottom: var(--border-style);
  @media (max-width: 567px) {
    padding: 50px 0;
  }
  &__text {
    max-width: 1010px;
    margin: auto;
    line-height: 2.25rem;
    @media (max-width: 567px) {
      font-size: 0.875rem;
      line-height: 1.4rem;
    }
  }
  &__image1 {
    max-width: 100%;
    height: auto;
    margin: 60px auto;
    @media (max-width: 567px) {
      margin: 30px 0;
    }
  }
  &__images-container {
    margin: 60px auto;
    max-width: 1530px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media (max-width: 567px) {
      margin: 30px 0;
    }
    img {
      max-width: 100%;
      height: auto;
      width: 49%;
      @media (max-width: 992px) {
        width: 100%;
      }
      &:first-child {
        @media (max-width: 992px) {
          margin-bottom: 30px;
        }
        @media (max-width: 567px) {
          margin-bottom: 10px;
        }
      }
    }
  }
  &__navigation {
    display: flex;
    justify-content: space-between;
    margin: 100px auto 0 auto;
    padding: 0 15px;
    max-width: 1530px;
    @media (max-width: 992px) {
      flex-direction: column-reverse;
      margin-top: 60px;
    }
    @media (max-width: 567px) {
      padding: 0;
      margin-top: 40px;
    }
    &-button {
      display: flex;
      align-items: center;
      width: 49%;
      background: var(--color-primary);
      cursor: pointer;
      &:hover {
        background: var(--color-primary-2);
      }
      @media (max-width: 992px) {
        width: 100%;
      }
      span {
        font-size: 1.5rem;
        line-height: 2.1rem;
        font-weight: 600;
        color: #ffffff;
        @media (max-width: 1100px) {
          width: 80px;
        }
        @media (max-width: 992px) {
          width: unset;
        }
        @media (max-width: 567px) {
          font-size: 0.75rem;
          line-height: 1.05rem;
        }
      }
      &:first-child {
        @media (max-width: 992px) {
          margin-top: 20px;
        }
        @media (max-width: 567px) {
          margin-top: 10px;
        }
        span {
          margin: 0 70px 0 79px;
          @media (max-width: 1400px) {
            margin: 0 auto;
          }
          @media (max-width: 390px) {
            margin: 0 15px 0 47px;
          }
        }
        img {
          margin-right: auto;
          @media (max-width: 567px) {
            width: 200px;
          }
          @media (max-width: 390px) {
            width: 98px;
          }
        }
      }
      &:last-child {
        span {
          margin: 0 79px 0 70px;
          @media (max-width: 1400px) {
            margin: 0 auto;
          }
          @media (max-width: 390px) {
            margin: 0 47px 0 15px;
          }
        }
        img {
          margin-left: auto;
          @media (max-width: 567px) {
            width: 200px;
          }
          @media (max-width: 390px) {
            width: 98px;
          }
        }
      }
      .custom-arrow.custom-arrow_enabled {
        border: 1px #ffffff solid;
      }
      .custom-arrow {
        background: transparent;
        transition: 0.2s;
      }
    }
    .disabled {
      background: var(--color-secondary-1);
      cursor: unset;
      span {
        color: #000000;
      }
    }
  }
}
