.custom-popup {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  &__wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 750px;
    width: 100%;
    height: 337px;
    background-color: white;
  }
  &__close-btn {
    position: absolute;
    border: none;
    top: 0;
    right: 0;
    padding: 28px;
    cursor: pointer;
    background-color: transparent;
    svg {
      fill: var(--color-primary);
      height: 24px;
      width: 24px;
    }
    &:hover {
      background-color: var(--color-primary);
      svg {
        fill: white;
      }
    }
  }
}
