.article-item {
  padding: 40px 15px;
  &__img {
    display: block;
    width: 100%;
    object-fit: cover;
    height: 307px;
    @media (max-width: 576px) {
      height: 177px;
    }
  }
  @media (max-width: 992px) {
    padding: 15px;
  }
}
