:root {
  --container-width: 1530px;
  --hero-container-width: 1920px;

  --default-font: "Montserrat", sans-serif;
  --secondary-font: "Myanmar MN", sans-serif;
  --secondary-font-2: "Poppins", sans-serif;
  --secondary-font-3: "Proxima Nova", sans-serif;

  --color-primary: #365eac;
  --color-primary-2: #113b8c;
  --color-secondary-1: #efefef;
  --color-secondary-2: #9899a0;
  --color-secondary-3: #e2e2e2;
  --color-error: #dc1c1c;

  --size-title: 3rem;
  --size-text: 1.125rem;

  --border-style: 1px solid #9899a0;
  --line-height-text: 1.8rem;
}
