.article-form {
  border-bottom: var(--border-style);
  @media (max-width: 576px) {
    border-top: var(--border-style);
  }
  &__container {
    padding: 0 0 135px;
    max-width: 1310px !important;
    @media (max-width: 576px) {
      padding: 0 0 50px;
    }
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: var(--border-style);
    border-bottom: var(--border-style);
    padding: 20px 0;
    @media (max-width: 576px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: none;
      position: relative;
      &::after {
        content: "";
        width: 100vw;
        display: block;
        height: 0.5px;
        border-top: var(--border-style);
        position: absolute;
        bottom: 0;
      }
    }
  }
  &__button-wrap {
    display: flex;
    align-items: center;
    .article-control-link:not(:last-child),
    .article-control-button:not(:last-child) {
      border-right: 0.5px solid #bababa;
    }
    svg {
      fill: black;
    }
  }
  &__control-wrap {
    display: flex;
    align-items: center;
  }
  &__controls {
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.438rem;
    &_button {
      border: none;
      outline: none;
      background-color: transparent;
      display: flex;
      img {
        cursor: pointer;
      }
      span {
        margin-left: 5px;
        font-weight: 600;
        color: #000000;
      }
    }
    &_span {
      font-weight: 400;
    }
    &:not(:last-child) {
      margin-right: 44px;
      @media (max-width: 1400px) {
        margin-right: 20px;
      }
    }
    @media (max-width: 576px) {
      font-size: 0.75rem;
      margin-bottom: 32px;
      height: 25px;
    }
  }
  &__form {
    padding: 56px 0 18px;
    display: flex;
    position: relative;
    a {
      margin-right: 47px;
      @media (max-width: 576px) {
        height: 35px;
        width: 35px;
        min-width: 35px;
        margin-right: 16px;
        img {
          height: 100%;
          width: 100%;
        }
      }
    }
  }
  &__form-wrap {
    display: flex;
    flex-direction: column;
  }
  .button-read-more {
    align-self: flex-end;
    margin-bottom: 30px;
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 23px;
    color: var(--color-primary);
    padding: 0;
    @media (max-width: 576px) {
      font-size: 14px;
      line-height: 18px;
      margin-left: 0;
    }
  }
  &__input {
    display: block;
    flex-grow: 1;
    border: 1px solid #9d9d9d;
    border-right: none;
    border-radius: 0;
    padding: 12px 25px;
    resize: none;
    font-family: var(--secondary-font-2);
    font-size: 1rem;
    font-weight: 500;
    line-height: 26px;
    outline: none;
    &-wrap {
      display: flex;
      width: 100%;
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 100px;
        width: 100%;
        cursor: pointer;
        background-color: var(--color-secondary-1);
        border: 1px solid #9d9d9d;
        svg {
          fill: #9d9d9d;
          @media (max-width: 576px) {
            flex-shrink: 0;
            height: 16px;
            width: 16px;
          }
        }
        @media (hover: hover) {
          &:hover {
            background-color: var(--color-primary);
            border-color: var(--color-primary);
            svg {
              fill: white;
            }
          }
        }
        @media (max-width: 576px) {
          max-width: 31px;
          height: 35px;
        }
      }
    }
    &::-webkit-input-placeholder {
      font-family: var(--secondary-font-2);
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: 0;
      text-align: left;
      @media (max-width: 576px) {
        font-size: 12px;
        line-height: 19px;
      }
    }

    @media (max-width: 576px) {
      max-height: 35px;
      padding: 6px 12px;
      overflow: hidden;
      font-size: 10px;
      line-height: 20px;
    }
  }
  &__comment-wrap {
    padding-bottom: 21px;
    width: 100%;
  }
  .custom-button {
    margin: 0 auto;
  }
  .custom-popup {
    &__wrap {
      padding: 70px 0;
      z-index: 1;
      @media (max-width: 760px) {
        width: 48%;
        max-width: none;
        h2 {
          font-size: 24px;
        }
      }
      @media (max-width: 576px) {
        padding: 70px 31px;
        .article-form__sign-btn {
          max-width: initial;
          width: 80%;
          font-size: 15px;
        }
      }
    }
    h2 {
      text-align: center;
      margin-bottom: 30px;
    }
    .article-form__sign-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      font-weight: 700;
      line-height: 24px;
      margin: 0 auto;
      max-width: 490px;
      width: 100%;
      background-color: transparent;
      outline: none;
      border: 1px solid #000000;
      border-radius: 200px;
      height: 48px;
      font-family: var(--secondary-font-3);
      color: #000000;
      cursor: pointer;
      &:hover {
        color: var(--color-primary);
        border-color: var(--color-primary);
      }
      img {
        margin-right: 10px;
      }
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }
  div.modal-window {
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
  }
}
body.hidden {
  overflow: hidden;
}
