.article-control-link,
.article-control-button {
  padding: 0 12px;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  &:not(:last-child) {
    border-right: 0.5px solid #ffffff4d;
  }
  svg {
    display: block;
    fill: white;
  }
}
