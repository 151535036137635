.projects-hero {
  border-bottom: var(--border-style);
  .slider-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    .keen-slider__slide {
      height: 688px;
      width: 100%;
      object-fit: cover;
      @media (max-width: 992px) {
        height: 400px;
      }
      @media (max-width: 576px) {
        height: 218px;
      }
    }
    .dots {
      display: flex;
      position: absolute;
      bottom: 40px;
      @media (max-width: 576px) {
        bottom: 20px;
      }
      .dot {
        border-radius: 50%;
        background: #ffffff;
        border: none;
        display: block;
        height: 18px;
        width: 18px;
        padding: 0;
        cursor: pointer;
        @media (max-width: 576px) {
          height: 9px;
          width: 9px;
        }
        &.active {
          background: var(--color-primary);
        }
        &:not(:last-child) {
          margin-right: 10px;
          @media (max-width: 576px) {
            margin-right: 5px;
          }
        }
      }
    }
  }
  &__controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 49px 0;
    @media (max-width: 992px) {
      flex-direction: column;
      align-items: flex-start;
    }
    @media (max-width: 576px) {
      padding: 30px 0;
    }
    &-title {
      font-family: var(--secondary-font);
      font-size: 3rem;
      font-weight: 700;
      color: var(--color-primary);
      text-transform: uppercase;
      line-height: 4.25rem;
      @media (max-width: 992px) {
        margin-bottom: 20px;
      }
      @media (max-width: 576px) {
        font-size: 1.5rem;
        line-height: 2.125rem;
      }
    }
  }
  &__arrows {
    display: flex;
    align-items: center;
    @media (max-width: 992px) {
      width: 100%;
    }
    p {
      display: none;
      margin-right: auto;
      font-size: 1.2rem;
      @media (max-width: 992px) {
        display: block;
      }
      @media (max-width: 576px) {
        font-size: 0.75rem;
      }
    }
  }
  .custom-arrow_left {
    margin-right: 40px;
    @media (max-width: 576px) {
      margin-right: 20px;
    }
  }
}
