.article-comment {
  display: flex;
  margin-bottom: 40px;
  position: relative;
  &__user-img {
    @media (max-width: 576px) {
      img {
        height: 35px;
        width: 35px;
      }
    }
  }
  @media (max-width: 576px) {
    margin-bottom: 30px;
  }
  &__content-wrap {
    margin-left: 47px;
    @media (max-width: 576px) {
      margin-left: 16px;
    }
  }
  &__header {
    display: flex;
    justify-content: space-between;
    h3 {
      font-size: 1.125rem;
      font-weight: 700;
      line-height: 23px;
      margin-bottom: 10px;
      @media (max-width: 576px) {
        font-size: 12px;
        line-height: 16px;
      }
    }
    p {
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      color: #c4c4c4;
      @media (max-width: 576px) {
        font-size: 10px;
        line-height: 13px;
      }
    }
  }
  &__text {
    font-family: var(--default-font);
    font-size: 1rem;
    line-height: 160%;
    color: #000000;
    margin-bottom: 16px;
    @media (max-width: 576px) {
      font-size: 12px;
      line-height: 19px;
      margin-bottom: 5px;
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      &.article-comment-mobile {
        -webkit-line-clamp: initial;
      }
    }
  }
  .article-comment__like {
    display: flex;
    align-items: center;
    img {
      display: block;
      margin-right: 2px;
    }
    &_span {
      display: block;
      font-weight: 600;
      font-size: 14px;
      line-height: 130%;
      &_liked {
        color: var(--color-primary);
      }
    }
  }
  .button-read-more {
    display: none;
    margin-bottom: 20px;
    font-size: 12px !important;
    color: black !important;
    @media (max-width: 576px) {
      display: block;
    }
  }
  &__controls {
    display: flex;
    justify-content: space-between;
    div {
      display: flex;
    }
    button {
      background-color: transparent;
      border: none;
      outline: none;
      font-family: var(--default-font);
      font-size: 14px;
      font-weight: 600;
      line-height: 1.125rem;
      display: block;
      color: black;
      cursor: pointer;
      &:not(:last-child) {
        margin-right: 22px;
        @media (max-width: 576px) {
          margin-right: 10px;
        }
      }
    }
  }
  &_additional {
    margin-left: 27px;
    padding-left: 20px;
    border-left: var(--border-style);
    position: relative;
    @media (max-width: 576px) {
      margin-left: 0;
    }
    .report-form {
      top: 151px;
    }
    .article-comment__user-img {
      align-self: baseline;
      @media (max-width: 576px) {
        img {
          height: 35px;
          width: 35px;
        }
      }
    }
    .article-comment__text {
      @media (max-width: 576px) {
        max-width: 500px;
      }
    }
  }
}
