.testimonials {
  &__container {
    max-width: 1590px;
    width: 100%;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
    overflow: visible;
  }
}
