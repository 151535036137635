.custom-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
  width: 90px;
  box-sizing: border-box;
  background: transparent;
  border-radius: 50%;
  border: none;
  outline: none;
  cursor: pointer;
  &,
  & * {
    transition: 0.2s;
    -webkit-tap-highlight-color: transparent;
  }
  svg {
    background: none !important;
  }
  &_disabled {
    border: var(--border-style);
    @media (hover: hover) {
      &:hover {
        background: transparent;
      }
    }
    &:active {
      background: transparent;
    }
    svg {
      border: none;
    }
    path {
      fill: #9899a0;
    }
  }
  &_enabled {
    background: var(--color-primary);
    @media (hover: hover) {
      &:hover {
        background: var(--color-primary-2);
      }
    }
    &:active {
      background: var(--color-primary-2);
    }
    path {
      fill: #fff;
    }
  }

  &_left {
    svg {
      transform: rotate(180deg);
    }
  }
  &_left,
  &_right {
    &:hover {
      cursor: pointer;
    }
  }
  @media (max-width: 576px) {
    width: 40px;
    height: 40px;
    svg {
      width: 26px;
    }
  }
}
