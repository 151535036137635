.contact-us {
  &__container {
    padding: 60px 0 54px;
    @media (max-width: 576px) {
      padding: 40px 0 50px;
    }
  }
  &__title {
    padding-bottom: 60px;
    @media (max-width: 576px) {
      font-size: 24px;
      line-height: 34px;
      padding-bottom: 40px;
    }
  }
  &__row {
    display: flex;
    justify-content: space-between;
    @media (max-width: 992px) {
      flex-direction: column;
    }
  }
  &__form-wrap {
    width: 50%;
    padding: 50px 130px;
    background-color: var(--color-secondary-1);
    position: relative;
    align-self: baseline;
    .custom-button {
      position: absolute;
      right: -77px;
      bottom: -77px;
      @media (max-width: 992px) {
        right: 0;
        bottom: -100px;
      }
      @media (max-width: 576px) {
        bottom: -55px;
      }
    }
    @media (max-width: 1330px) {
      padding: 50px;
      width: 45%;
    }
    @media (max-width: 992px) {
      width: 100%;
      margin-bottom: 150px;
    }
    @media (max-width: 576px) {
      margin-bottom: 100px;
      padding: 30px 30px 72px;
    }
  }
  &__chat-btn {
    display: block;
    max-width: 181px;
    width: 100%;
    border-radius: 100px;
    background-color: var(--color-primary);
    padding: 19px 0;
    font-family: var(--default-font);
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 29px;
    color: white;
    border: none;
    outline: none;
    cursor: pointer;
    text-transform: uppercase;
    align-self: end;
    &:hover {
      background-color: var(--color-primary-2);
    }
    @media (max-width: 992px) {
      margin: 0 auto;
    }
    @media (max-width: 576px) {
      max-width: 576px;
    }
  }
  &__content {
    width: 40%;
    display: flex;
    flex-direction: column;
    @media (max-width: 1330px) {
      width: 45%;
    }
    @media (max-width: 992px) {
      width: 100%;
    }
    ul {
      border: 1px solid #9899a0;
      margin-bottom: 20px;
    }
    li:not(:last-child) {
      border-bottom: 1px solid #9899a0;
    }

    li {
      display: block;
      background-color: white;
      div {
        text-align: center;
        padding: 40px 0;
        h4 {
          font-size: 24px;
          font-weight: 700;
          line-height: 29px;
          text-transform: uppercase;
          margin-bottom: 15px;
          @media (max-width: 576px) {
            font-size: 18px;
            line-height: 22px;
          }
        }
        a {
          font-size: 1.125rem;
          font-weight: 400;
          line-height: 29px;
          @media (max-width: 576px) {
            font-size: 14px;
            line-height: 22px;
          }
        }
        @media (max-width: 576px) {
          padding: 30px 0;
        }
      }
    }
    li:last-child {
      padding: 87px 0 38px;
      div {
        position: relative;
        max-width: 360px;
        width: 100%;
        background-color: var(--color-secondary-1);
        margin: 0 auto;
        @media (max-width: 576px) {
          max-width: unset;
          width: unset;
          margin: 0 30px;
        }
      }
      h4 {
        font-size: 1.125rem;
        font-weight: 600;
        line-height: 29px;
        max-width: 260px;
        margin: 15px auto 0;
        text-transform: initial;
        @media (max-width: 576px) {
          font-size: 14px;
          line-height: 22px;
          margin-top: 30px;
        }
      }
      img {
        position: absolute;
        top: -50px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  .contact-form {
    text-align: center;
    &__title {
      font-size: 1.125rem;
      font-weight: 600;
      line-height: 29px;
      margin-bottom: 20px;
      @media (max-width: 576px) {
        font-size: 14px;
        line-height: 22.4px;
        margin-bottom: 10px;
      }
    }
    label {
      font-family: var(--secondary-font-2);
      font-size: 1rem;
      font-weight: 400;
      line-height: 26px;
      display: block;
      margin-bottom: 5px;
      text-align: left;
      position: relative;
      @media (max-width: 576px) {
        font-size: 12px;
        line-height: 19px;
      }
      &.contact-form__label_error {
        color: var(--color-error);
        div {
          display: flex;
          justify-content: space-between;
          span {
            text-align: right;
            margin-left: 10px;
          }
        }

        input,
        textarea {
          border-color: var(--color-error);
        }
      }
    }
    input,
    textarea {
      display: block;
      padding: 12px 15px;
      width: 100%;
      font-family: var(--secondary-font-2);
      font-size: 1rem;
      font-weight: 500;
      line-height: 26px;
      border: 0.5px solid #9d9d9d;
      outline: none;
      margin-bottom: 10px;
      &:focus {
        outline: 1.5px solid var(--color-primary-2);
        box-sizing: border-box;
        border-color: var(--color-primary);
      }
      @media (max-width: 576px) {
        font-size: 0.75rem;
      }
    }
    textarea {
      resize: none;
      height: 127px;
    }
    ::-webkit-input-placeholder {
      font-family: var(--secondary-font-2);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: 0;
      text-align: left;
      color: #d2d2d2;
      @media (max-width: 576px) {
        font-size: 0.75rem;
      }
    }
  }
}
