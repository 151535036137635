.close-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  outline: none;
  border: none;
  @media (hover: hover) {
    &:hover {
      cursor: pointer;
      background-color: var(--color-primary);
      path {
        fill: #ffffff;
      }
    }
  }
  &:active {
    background-color: var(--color-primary);
    path {
      fill: #ffffff;
    }
  }
}
