.modal-window {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200vw;
  height: 200vh;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(54, 94, 172, 0.3);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
}
