.help-card {
  width: 49%;
  min-height: 327px;
  border-bottom-right-radius: 100px;
  background-color: var(--color-secondary-1);
  display: flex;
  flex-direction: column;
  padding: 0 130px;
  margin-bottom: 20px;
  @media (max-width: 992px) {
    width: 100%;
    padding: 30px;
    min-height: 196px;
    border-bottom-right-radius: 75px;
  }
  @media (max-width: 568px) {
    border-bottom-right-radius: 50px;
    padding: 30px 29px 24px;
  }
  &__title-container {
    align-items: center;
    display: flex;
    flex: 0 0 33%;
    flex-grow: 0;
    padding: 60px 0 40px;
    @media (max-width: 992px) {
      padding: 0 0 17px;
    }
  }
  &__icon {
    height: 80px;
    padding-right: 40px;
    @media (max-width: 992px) {
      height: 60px;
      padding-right: 30px;
    }
    @media (max-width: 568px) {
      height: 40px;
      padding-right: 14px;
    }
  }
  &__title {
    font-weight: 600;
    font-size: 1.5rem;
    max-width: 390px;
    @media (max-width: 992px) {
      font-size: 1.175rem;
    }
    @media (max-width: 568px) {
      line-height: 1.125rem;
      font-size: 0.875rem;
    }
  }
  &__text {
    max-width: 490px;
    @media (max-width: 992px) {
      max-width: 100%;
      font-size: 1rem;
    }
    @media (max-width: 568px) {
      padding-top: 5px;
      font-size: 0.75rem;
      line-height: 1.175rem;
    }
  }
}
