.project-item {
  position: relative;
  width: fit-content;
  display: flex;
  margin-bottom: 60px;
  flex-basis: 48.7%;
  max-width: 750px;
  min-height: 210px;
  @media (max-width: 1050px) {
    flex-basis: 100%;
  }
  @media (max-width: 568px) {
    margin-bottom: 20px;
  }
  &__name {
    position: absolute;
    background: var(--color-secondary-1);
    font-family: var(--default-font);
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.813rem;
    padding: 38px 40px;
    width: 100%;
    bottom: 0;
    transition: 0.2s;
    @media (max-width: 568px) {
      font-size: 0.75rem;
      line-height: 0.914rem;
      padding: 20px 15px;
    }
  }
  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    bottom: 60px;
    right: 40px;
    background: var(--color-primary);
    border-radius: 50%;
    width: 90px;
    height: 90px;
    outline: none;
    border: none;
    transition: 0.2s;
    @media (max-width: 568px) {
      width: 55px;
      height: 55px;
      bottom: 27px;
      right: 15px;
      img {
        width: 28px;
      }
    }
  }
  img {
    max-width: 100%;
    height: auto;
  }
  &:hover &__button {
    background: var(--color-primary-2);
  }
  &:hover &__name {
    background: var(--color-secondary-3);
  }
}
