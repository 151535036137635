@font-face {
  font-family: "Montserrat";
  src: local(""), url("../fonts/Montserrat-Regular.woff2") format("woff2"),
    url("../fonts/Montserrat-Regular.woff") format("woff"),
    url("../fonts/Montserrat-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: local(""), url("../fonts/Montserrat-Bold.ttf") format("truetype"),
    url("../fonts/Montserrat-Bold.woff") format("woff"),
    url("../fonts/Montserrat-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: local(""), url("../fonts/Montserrat-SemiBold.woff2") format("woff2"),
    url("../fonts/Montserrat-SemiBold.woff") format("woff"),
    url("../fonts/Montserrat-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Myanmar MN";
  src: local(""), url("../fonts/MyanmarMN-Bold.woff") format("woff"),
    url("../fonts/MyanmarMN-Bold.woff2") format("woff2"),
    url("../fonts/MyanmarMN-Bold-02.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Myanmar MN";
  src: local(""), url("../fonts/myanmar_mn_regular.ttf") format("truetype"),
    local(""), url("../fonts/MyanmarMN-01.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Myanmar MN";
  src: local(""), url("../fonts/MyanmarMN-01.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: local(""), url("../fonts/Poppins-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: local(""), url("../fonts/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: local(""), url("../fonts/ProximaNova-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
