body {
  font-family: var(--default-font);
  min-height: 100vh;
  background-image: url("../images/bg.png");
}
.container {
  max-width: var(--container-width);
  width: 100%;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
  overflow: visible;
  @media (max-width: var(--container-width)) {
    max-width: 1200px;
    margin: 0 auto;
  }
}
.hero-container {
  position: relative;
  max-width: var(--hero-container-width);
  margin: 0 auto;
}
#root {
  overflow: hidden;
}
h1 {
  font-family: var(--secondary-font);
  font-size: var(--size-title);
  color: var(--color-primary);
  font-style: normal;
  font-weight: 700;
  line-height: 4.25rem;
  letter-spacing: 0;
  text-align: left;
  text-transform: uppercase;
}
h2 {
  font-size: 2.25rem;
  font-family: var(--secondary-font);
  text-transform: uppercase;
  font-weight: 700;
  @media (max-width: 992px) {
    font-size: 1.8rem;
  }
  @media (max-width: 568px) {
    font-size: 1.5rem;
  }
}
p {
  font-size: var(--size-text);
  line-height: 1.8rem;
}
.button-read-more {
  background: transparent;
  border: none;
  outline: none;
  text-decoration: underline;
  font-family: var(--default-font);
  font-weight: 700;
  line-height: 1.813rem;
  color: black;
  cursor: pointer;
}
