.how-we-help {
  padding: 158px 0 125px;
  border-bottom: var(--border-style);
  @media (max-width: 992px) {
    padding: 50px 0 30px;
  }
  &__title {
    padding: 0 15px 70px;

    @media (max-width: 992px) {
      padding: 0 0 40px;
      font-size: 1.8rem;
    }
    @media (max-width: 568px) {
      max-width: 75%;
      line-height: 2rem;
      font-size: 1.5rem;
      padding-bottom: 33px;
    }
  }
  &__proposal {
    display: flex;
    flex: 0 0 50%;
    padding: 0 15px;
    flex-wrap: wrap;
    justify-content: space-between;
    @media (max-width: 992px) {
      flex: 0 0 100%;
      padding: 0;
    }
  }
}
