.about-us-request {
  &__container {
    padding: 85px 0 137px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 992px) {
      flex-direction: column-reverse;
    }
    @media (max-width: 576px) {
      padding: 50px 0 101px;
    }
  }
  &__form-wrap {
    position: relative;
    width: 48%;
    @media (max-width: 992px) {
      width: 100%;
    }
  }
  &__title-wrap {
    width: 33.5%;
    margin-top: -218px;
    h2 {
      margin-bottom: 20px;
    }
    p {
      font-family: var(--default-font);
      font-size: 1.125rem;
      line-height: 1.813rem;
      letter-spacing: 0;
      text-align: left;
      @media (max-width: 576px) {
        font-size: 0.875rem;
      }
    }
    @media (max-width: 992px) {
      width: 100%;
      margin-bottom: 30px;
      margin-top: 0;
    }
  }
  .custom-button {
    position: absolute;
    right: -77px;
    bottom: -77px;
    @media (max-width: 992px) {
      right: 0;
    }
    @media (max-width: 576px) {
      bottom: -50px;
    }
  }
  .request-form {
    background: var(--color-secondary-1);
    padding: 70px 130px;

    input,
    textarea {
      display: block;
      max-width: 490px;
      width: 100%;
      padding: 12px;
      padding-left: 15px;
      border: 0.5px solid #9d9d9d;
      margin-bottom: 10px;
      font-family: var(--secondary-font-2);
      font-size: 1rem;
      font-weight: 500;
      line-height: 1.625rem;
      box-sizing: border-box;
      &:focus {
        outline: 2px solid var(--color-primary-2);
        box-sizing: border-box;
        border-color: var(--color-primary);
      }
      @media (max-width: 992px) {
        max-width: 992px;
      }
      @media (max-width: 576px) {
        font-size: 0.75rem;
        padding: 8px;
        padding-left: 12px;
      }
    }
    textarea {
      height: 127px;
      resize: none;
    }
    &__label {
      font-family: var(--secondary-font-2);
      font-size: 1rem;
      line-height: 26px;
      margin-bottom: 5px;
      position: relative;
      width: 100%;
      display: block;
      @media (max-width: 576px) {
        font-size: 0.75rem;
        line-height: 19px;
      }
      div {
        display: flex;
        justify-content: space-between;
        span {
          text-align: right;
          margin-left: 10px;
        }
      }
      &_error {
        color: var(--color-error);
        input,
        textarea {
          border-color: var(--color-error);
        }
      }
    }
    @media (max-width: var(--container-width)) {
      padding: 50px 70px 100px;
    }
    @media (max-width: 1300px) {
      padding: 70px 50px;
    }
    @media (max-width: 576px) {
      padding: 30px 30px 70px;
    }
  }
  & *::-webkit-input-placeholder,
  & *::placeholder {
    font-weight: 400;
  }
}
::-webkit-input-placeholder {
  font-family: var(--secondary-font-2);
  font-size: 1rem;
  line-height: 26px;
  letter-spacing: 0;
  font-weight: 400;
  color: #d2d2d2;
  @media (max-width: 576px) {
    font-size: 0.75rem;
    line-height: 19px;
  }
}
