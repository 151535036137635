.about-us {
  &__first-img {
    display: block;
    width: 100%;
    height: 496px;
    object-fit: cover;
    @media (max-width: 992px) {
      height: 300px;
    }
    @media (max-width: 576px) {
      height: 127px;
    }
  }
}
