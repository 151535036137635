.article-header {
  border-bottom: var(--border-style);
  &__container {
    max-width: 1270px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    @media (max-width: 576px) {
      flex-direction: column;
      text-align: center;
    }
  }
  &__date {
    font-family: var(--default-font);
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 29px;
    background-color: var(--color-secondary-1);
    padding: 19px 60px;
    @media (max-width: 576px) {
      font-size: 14px;
    }
  }
  &__controls {
    background-color: var(--color-primary);
    padding: 23px 32px;
    display: flex;
    align-content: baseline;
    @media (max-width: 576px) {
      justify-content: center;
    }
  }
}
