.video {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  &.container {
    width: 100vw;
  }
  &__thumbnail {
    position: relative;
    width: 100%;
    height: calc(100vw / (15 / 9));
    max-height: 930px;
    object-fit: cover;
    box-shadow: 0 0 0 20px var(--color-primary);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 1400px) {
      max-height: calc((var(--vh, 1vh) * 100) - 40px);
    }
    &_image {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -1;
    }
  }
  &__play-button {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 18.8vw;
    max-width: 100px;
    height: 18.8vw;
    max-height: 100px;
  }
  &__close-btn {
    position: absolute;
    right: 0;
    top: 0;
    width: 15vw;
    height: 15vw;
    max-width: 79px;
    max-height: 79px;
    svg {
      width: 4.8vw;
      height: 4.8vw;
      max-width: 24px;
      max-height: 24px;
    }
  }
}
