.custom-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 155px;
  height: 155px;
  box-sizing: border-box;
  padding: 0 33px;
  background: var(--color-primary);
  font-family: var(--default-font);
  font-weight: 700;
  font-size: var(--size-text);
  line-height: 29px;
  outline: none;
  border-radius: 50%;
  color: white;
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  &:active {
    background: var(--color-primary-2);
  }
  @media (hover) {
    &:hover {
      background: var(--color-primary-2);
    }
  }
  @media (max-width: 576px) {
    height: 103px;
    width: 103px;
    font-size: 0.75rem;
    line-height: 1.188rem;
    padding: 0 15px;
  }
}
