.testimonials-card {
  padding: 0 15px;
  margin: 30px 0;
  width: 33.33%;
  max-width: 550px;
  height: 577px;
  &__wrapper {
    min-height: 577px;
    background-color: var(--color-secondary-1);
  }
  &__header {
    padding: 0 35px;
    height: 135px;
    background-color: var(--color-primary);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__quote {
    height: 55px;
    width: 75.13px;
    filter: brightness(0) invert(1);
    &_flipped {
      transform: rotate(180deg);
    }
  }
  &__content {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0 50px 60px;
    &_image {
      padding-top: 115px;
    }
    &_video {
      padding-top: 306px;
    }
  }
  &__preview {
    position: absolute;
    left: 50%;
    top: 0;
    &,
    & * {
      -webkit-user-drag: none;
    }
    &_image {
      display: flex;
      width: 170px;
      height: 170px;
      overflow: hidden;
      border-radius: 50%;
      transform: translate(-50%, -50%);
      object-fit: cover;
    }
    &_video {
      left: 0;
      top: -135px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: calc(100% - 60px);
      height: calc(100% - 60px);
      object-fit: cover;
      border: none;
      padding: 0;
      margin: 30px;
      @media (hover: hover) {
        &:hover {
          cursor: pointer;
          .testimonials-card__button {
            background-color: var(--color-primary);
            & * {
              fill: #fff;
            }
          }
        }
      }
      &:active {
        .testimonials-card__button {
          background-color: var(--color-primary);

          & * {
            fill: #fff;
          }
        }
      }
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        filter: brightness(0.8);
      }
    }
  }
  &__button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }
  p {
    font-size: 1.5rem;
    font-family: var(--secondary-font);
    letter-spacing: 0;
    line-height: 34px;
    text-align: center;
  }
  &__name {
    text-transform: uppercase;
    font-weight: 700;
  }
  & &__position {
    font-weight: 400;
    padding-top: 5px;
    text-align: left;
  }
  & &__testimonials {
    padding-top: 20px;
    font-family: var(--default-font);
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 29px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
  }
  &__video {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 80vh;
    width: 80vw;
  }
  @media (max-width: 1440px) {
    width: 50%;
  }
  @media (max-width: 992px) {
    width: 100%;
  }
  @media (max-width: 576px) {
    padding: 0;
    height: 368px;
    margin: 10px 0;
    &__wrapper {
      min-height: 368px;
    }
    &__header {
      height: 80px;
      padding: 0 13px;
    }
    &__content {
      padding: 0 30px;
      &_image {
        padding-top: 60px;
      }
      &_video {
        padding-top: 175px;
      }
    }
    &__quote {
      height: 37px;
    }
    &__preview {
      &_image {
        height: 100px;
        width: 100px;
      }
      &_video {
        height: calc(114% - 32px);
        width: calc(100% - 32px);
        margin: 16px;
        top: -80px;
      }
    }
    &__button {
      width: 60px;
      height: 60px;
    }
    & &__testimonials {
      padding: 10px 0 0;
      font-family: var(--default-font);
      font-size: 0.75rem;
      font-weight: 400;
      line-height: 1.188rem;
      text-align: center;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 7;
    }
    & &__name {
      font-family: var(--default-font);
      font-size: 1.125rem;
    }
    & &__position {
      font-family: var(--secondary-font);
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 15px;
    }
  }
}
