.nav {
  width: 100%;
  padding: 65px 0 68px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 576px) {
    padding: 30px 0;
  }
}
.nav-element {
  font-weight: 600;
  font-size: 1.313rem;
  line-height: 1.625rem;
  text-transform: uppercase;
  color: #000;
  &__active {
    color: var(--color-primary);
  }
  @media (max-width: 1540px) {
    font-size: 14px;
  }
  @media (max-width: 992px) {
    font-size: 1.313rem;
  }
  @media (max-width: 567px) {
    font-size: 14px;
  }
}
