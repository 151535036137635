.about-us-summary {
  border-bottom: 1px solid var(--color-secondary-2);
  overflow: hidden;
  &__container {
    display: flex;
    justify-content: space-between;
    @media (max-width: 1440px) {
      flex-wrap: wrap;
      flex-direction: column-reverse;
    }
  }
  &__info {
    min-width: calc(50% + 15px);
    padding-left: 15px;
    @media (max-width: 992px) {
      padding: 5px 0 0;
    }
  }
  &__text {
    padding: 158px 185px 0 0;
    h2 {
      margin-bottom: 28px;
      letter-spacing: -1.4px;
    }
    p {
      padding-bottom: 25px;
    }
    p:last-child {
      padding-bottom: 75px;
    }
    @media (max-width: 1600px) {
      padding-right: 25px;
    }
    @media (max-width: 1440px) {
      padding-top: 75px;
    }
    @media (max-width: 992px) {
      padding: 20px 0 0;
      h2 {
        margin-bottom: 19px;
      }
      p {
        padding-bottom: 17px;
        font-size: 1rem;
      }
      p:last-child {
        padding-bottom: 50px;
      }
    }
    @media (max-width: 568px) {
      h2 {
        margin-bottom: 15px;
        letter-spacing: 0;
      }
      p {
        padding-bottom: 10px;
        font-size: 0.875rem;
        line-height: 1.375rem;
      }
      p:last-child {
        padding-bottom: 30px;
      }
    }
  }
  &__read-more {
    color: #000000;
    font-weight: 700;
    text-decoration: underline;
    outline: none;
    border: none;
    background-color: transparent;
    padding: 2px 0 30px;
    font-family: var(--default-font);
    font-size: 0.875rem;
    @media (min-width: 568px) {
      display: none;
    }
  }
  &__numbers {
    display: flex;
    justify-content: space-between;
    padding: 0 120px 50px 0;
    & p {
      color: var(--color-primary);
      font-weight: 700;
      font-size: 1.125rem;
      line-height: 1rem;
    }
    div {
      width: 135px;
      p:first-child {
        font-size: 2.25rem;
        line-height: 1rem;
        padding-bottom: 25px;
      }
    }
    @media (max-width: 1600px) {
      padding: 0 25px 80px 0;
    }
    @media (max-width: 1440px) {
      margin: 0 auto;
      max-width: 992px;
    }
    @media (max-width: 1366px) {
      padding: 0 25px 25px 0;
    }
    @media (max-width: 992px) {
      padding: 0 0 50px;
      & p {
        font-size: 0.9rem;
      }
      div {
        p:first-child {
          padding-bottom: 20px;
          font-size: 1.8rem;
        }
      }
    }
    @media (max-width: 568px) {
      padding: 0 0 45px;
      & p {
        max-width: 83px;
        font-size: 0.75rem;
      }
      div {
        width: auto;
        p:first-child {
          padding-bottom: 15px;
          font-size: 1.5rem;
        }
      }
    }
  }
  &__photo {
    border-left: var(--border-style);
    display: flex;
    min-width: calc(50% - 15px);
    padding: 100px;
    img {
      object-fit: contain;
      width: 100%;
    }
    @media (max-width: 1749px) {
      padding: 100px 50px;
      img {
        width: calc(100% + 50px);
      }
    }
    @media (min-width: 1750px) {
      img {
        object-fit: cover;
        width: 745px;
        height: 648px;
      }
    }
    @media (max-width: 1440px) {
      padding: 50px 0;
      border-left: none;
      position: relative;
      &:after {
        display: block;
        content: "";
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        width: 100vw;
        border-bottom: var(--border-style);
      }
      img {
        width: 100%;
      }
    }
  }
}
