.report-form {
  position: absolute;
  right: 0;
  max-width: 360px;
  width: 100%;
  background-color: var(--color-secondary-1);
  z-index: 1;
  border: 1px solid #9899a0;
  &__container {
    padding: 30px;
  }
  &__close-btn {
    position: absolute;
    right: 0;
    top: 0;
    border: none;
    outline: none;
    background-color: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;
    svg {
      fill: var(--color-primary);
    }
  }
  &__title {
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 23px;
    margin-bottom: 30px;
  }
  select {
    margin-bottom: 20px;
    width: 100%;
  }
  label {
    font-family: var(--secondary-font-2);
    font-size: 1rem;
    font-weight: 400;
    line-height: 26px;
    display: block;
    input {
      display: block;
      padding: 14.5px 15px;
      width: 100%;
      outline: none;
      border: 1px solid #9d9d9d;
      font-family: var(--secondary-font-2);
      font-size: 1rem;
      font-weight: 500;
      line-height: 26px;
    }
  }
  ::-webkit-input-placeholder {
    font-family: var(--secondary-font-2);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0;
    text-align: left;
    color: #d2d2d2;
  }
  .custom-select {
    margin-bottom: 20px;
  }
  &__report-btn {
    width: 100%;
    background-color: var(--color-primary);
    text-align: center;
    padding: 15px 0;
    font-family: var(--default-font);
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 23px;
    color: white;
    border: none;
    outline: none;
    cursor: pointer;
    @media (hover: hover) {
      &:hover {
        background: var(--color-primary-2);
      }
    }
  }
}
