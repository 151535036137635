.post-article {
  &__content {
    padding: 60px 0 0;
    border-radius: 0 0 48px 0;
    background: var(--color-secondary-1);
    @media (max-width: 768px) {
      padding: 40px 0 0;
    }
    @media (max-width: 576px) {
      padding: 30px 0 0;
    }
  }
  &__date {
    font-size: 1.125rem;
    padding-left: 70px;
    line-height: 1.813rem;
    color: black;
    margin-bottom: 20px;
    @media (max-width: 768px) {
      padding-left: 40px;
    }
    @media (max-width: 576px) {
      padding-left: 31px;
      font-size: 0.875rem;
      margin-bottom: 10px;
    }
  }
  &__title {
    padding-left: 70px;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.938rem;
    margin-bottom: 20px;
    @media (max-width: 768px) {
      padding-left: 40px;
    }
    @media (max-width: 576px) {
      padding-left: 31px;
      font-size: 1.25rem;
      margin-bottom: 10px;
    }
  }
  &__text {
    padding-left: 70px;
    font-size: 1.125rem;
    line-height: 1.813rem;
    margin-bottom: 30px;
    padding-right: 70px;
    @media (max-width: 768px) {
      padding-left: 40px;
    }
    @media (max-width: 576px) {
      padding-left: 31px;
      font-size: 0.875rem;
      margin-bottom: 10px;
    }
  }
  &__more-btn {
    padding-left: 70px;
    &.button-read-more {
      color: var(--color-primary);
    }
    font-size: 1.125rem;
    line-height: 1.438rem;
    margin-bottom: 31px;
    @media (max-width: 768px) {
      padding-left: 40px;
    }
    @media (max-width: 576px) {
      padding-left: 31px;
      font-size: 0.875rem;
    }
  }
  &__footer {
    display: flex;
    justify-content: flex-end;
  }
  &__control-wrap {
    background-color: var(--color-primary);
    border-radius: 48px 0;
    max-width: 490px;
    width: 100%;
    display: flex;
    padding: 29px 46px;
    @media (max-width: 992px) {
      max-width: 1000px;
      display: flex;
      justify-content: space-between;
      padding: 29px 70px;
    }
    @media (max-width: 768px) {
      padding: 29px 40px;
    }
    @media (max-width: 576px) {
      padding: 22px 31px;
    }
  }
  &__controls {
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.438rem;
    color: #ffffff;
    text-align: center;
    &_span {
      font-weight: 400;
    }
    &:not(:last-child) {
      margin-right: 42px;
      @media (max-width: 1400px) {
        margin-right: 20px;
      }
    }
    @media (max-width: 576px) {
      font-size: 0.75rem;
    }
  }
}
