.testimonials-hero {
  padding: 110px 15px 102px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:after {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    width: 100vw;
    border-bottom: var(--border-style);
  }
  &__title {
    h1 {
      padding-bottom: 10px;
      letter-spacing: -1.5px;
      font-family: var(--secondary-font);
      font-size: 3rem;
      font-style: normal;
      font-weight: 700;
      line-height: 68px;
      text-align: left;
      color: var(--color-primary);
      text-transform: uppercase;
    }
    p {
      font-family: var(--default-font);
      font-size: 18px;
      font-weight: 500;
      line-height: 29px;
      letter-spacing: 0.25px;
    }
  }
  @media (max-width: 992px) {
    flex-wrap: wrap;
    &__title {
      width: 100%;
    }
  }
  @media (max-width: 576px) {
    padding: 45px 0 40px;
    &__title {
      h1 {
        font-size: 1.625rem;
        line-height: 1.625rem;
        padding-bottom: 7px;
      }
      p {
        font-weight: 500;
        font-size: 0.8rem;
        line-height: 1.375rem;
        max-width: 65%;
      }
    }
  }
}
