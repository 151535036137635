.endorsements {
  background: var(--color-secondary-1);
  border-bottom: var(--border-style);
  &__container {
    padding: 150px 0;
    overflow: hidden !important;
    @media (max-width: 992px) {
      padding: 50px 0;
    }
  }
  &__title {
    text-align: right;
    margin-bottom: 60px;
    @media (max-width: 992px) {
      font-size: 1.875rem;
      margin-bottom: 40px;
      text-align: left;
    }
    @media (max-width: 576px) {
      font-size: 1.5rem;
      margin-bottom: 30px;
    }
  }
  &__arrows {
    display: flex;
  }
  .keen-slider {
    display: flex;
    margin-bottom: 50px;
    img.keen-slider__img {
      width: 100%;
      height: 100%;
    }
    @media (max-width: 992px) {
      margin-bottom: 40px;
    }
    @media (max-width: 576px) {
      margin-bottom: 20px;
    }
  }
  .custom-arrow_left {
    margin-right: 40px;
    @media (max-width: 576px) {
      margin-right: 20px;
    }
  }
  .dot {
    border-radius: 50%;
    background: #dddddd;
    border: none;
    display: block;
    height: 18px;
    width: 18px;
    cursor: pointer;
    &.active {
      background: var(--color-primary);
    }
    &:not(:last-child) {
      margin-right: 10px;
      @media (max-width: 576px) {
        margin-right: 5px;
      }
    }
    @media (max-width: 576px) {
      height: 9px;
      width: 9px;
    }
  }
  .dots {
    display: flex;
    @media (max-width: 576px) {
      display: none;
    }
  }
  &__controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
