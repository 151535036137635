.play-button {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: none;
  outline: none;
  background-color: #fff;
  cursor: pointer;
  &__icon {
    margin-left: 5%;
    width: 21%;
  }
  @media (hover: hover) {
    &:hover {
      background-color: var(--color-primary);
      & * {
        fill: #fff;
      }
    }
  }
  &:active {
    background-color: var(--color-primary);
    & * {
      fill: #fff;
    }
  }
}
