.project-hero {
  position: relative;
  border-bottom: var(--border-style);
  .slider-wrapper {
    position: relative;
    display: flex;
    margin-right: 16.9%;
    @media (max-width: 1400px) {
      margin: 0;
      justify-content: center;
    }
    .keen-slider__slide {
      height: 688px;
      width: 100%;
      object-fit: cover;
      @media (max-width: 992px) {
        height: 400px;
      }
      @media (max-width: 567px) {
        height: 218px;
      }
    }
    .dots {
      display: flex;
      position: absolute;
      bottom: 40px;
      left: 35.4%;
      @media (max-width: 1900px) {
        left: 20%;
      }
      @media (max-width: 1600px) {
        left: 7%;
      }
      @media (max-width: 1400px) {
        left: unset;
        text-align: center;
      }
      @media (max-width: 576px) {
        bottom: 20px;
      }
      .dot {
        border-radius: 50%;
        background: #ffffff;
        border: none;
        display: block;
        height: 14px;
        width: 14px;
        padding: 0;
        cursor: pointer;
        @media (max-width: 576px) {
          height: 9px;
          width: 9px;
        }
        &.active {
          background: var(--color-primary);
        }
        &:not(:last-child) {
          margin-right: 10px;
          @media (max-width: 576px) {
            margin-right: 5px;
          }
        }
      }
    }
  }
  &__controls {
    padding: 49px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 567px) {
      padding: 20px 0;
    }
    p {
      display: none;
      font-size: 1.2rem;
      @media (max-width: 1400px) {
        display: block;
      }
      @media (max-width: 567px) {
        font-size: 0.75rem;
      }
    }
  }
  &__info {
    background: var(--color-secondary-1);
    padding: 70px 130px 65px;
    position: absolute;
    right: 0;
    bottom: 0;
    min-width: 1075px;
    @media (max-width: 1400px) {
      position: relative;
      padding: 60px 15px;
      min-width: unset;
    }
    @media (max-width: 567px) {
      padding: 40px 15px;
    }

    &-title {
      font-size: 2.25rem;
      line-height: 3.19rem;
      font-weight: 700;
      margin-bottom: 5px;
      font-family: var(--secondary-font);
      color: black;
      text-transform: uppercase;
      @media (max-width: 567px) {
        font-size: 1.5rem;
        line-height: 2.124rem;
      }
    }
    &-subtitle {
      font-size: 1.5rem;
      line-height: 2.12rem;
      font-weight: 400;
      @media (max-width: 567px) {
        font-size: 1.125rem;
        line-height: 1.563rem;
      }
    }
    &-main {
      display: flex;
      margin-top: 40px;
      @media (max-width: 992px) {
        flex-direction: column;
        margin: 0;
      }
      img {
        margin-right: 30px;
        @media (max-width: 567px) {
          width: 30px;
          margin-right: 15px;
        }
      }
      div {
        display: flex;
        align-items: center;
        min-width: 390px;
        @media (max-width: 992px) {
          margin-top: 20px;
        }
      }
      span,
      a {
        font-size: 1.125rem;
        line-height: 1.371rem;
        font-weight: 600;
        @media (max-width: 567px) {
          font-size: 0.875rem;
        }
      }
      a {
        color: var(--color-primary);
        text-decoration: underline;
      }
    }
  }
  .custom-arrow_left {
    margin-right: 40px;
    @media (max-width: 576px) {
      margin-right: 20px;
    }
  }
  &__arrows {
    display: flex;
  }
}
