.define-business {
  border-bottom: var(--border-style);
  &__video {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 646px;
    overflow: hidden;
    border-bottom: var(--border-style);
    @media (max-width: 1440px) {
      height: 30vw;
    }
    @media (max-width: 992px) {
      height: 68vw;
    }
  }
  &__thumbnail {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__play_button {
    z-index: 0;
  }

  &__text-container {
    overflow: hidden;
  }
  &__text {
    min-height: 259px;
    display: flex;
    padding: 0 15px;
    @media (max-width: 992px) {
      padding: 0;
      display: block;
    }
  }
  &__title {
    display: flex;
    align-items: center;
    min-width: calc(50% + 15px);
    @media (max-width: 992px) {
      padding: 50px 0;
      position: relative;
      &:after {
        display: block;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        content: "";
        width: 100vw;
        height: 1px;
        background-color: var(--color-secondary-2);
      }
    }
    @media (max-width: 568px) {
      padding: 30px 0 35px;
    }
    h1 {
      letter-spacing: -1px;
      max-width: 620px;
      @media (max-width: 992px) {
        line-height: 1.85rem;
        font-size: 1.75rem;
      }
      @media (max-width: 568px) {
        font-size: 1.5rem;
        letter-spacing: 0;
        line-height: 2rem;
      }
    }
  }
  &__paragraph {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    position: relative;
    &:after {
      display: block;
      content: "";
      height: 100%;
      width: 100vw;
      background-color: var(--color-secondary-1);
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      @media (max-width: 992px) {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    align-items: center;
    background-color: var(--color-secondary-1);
    border-left: 1px solid var(--color-secondary-2);
    @media (max-width: 992px) {
      padding: 40px 0;
      border-left: none;
      justify-content: flex-start;
    }
    @media (max-width: 568px) {
      padding: 40px 0;
    }
    p {
      font-size: var(--size-text);
      line-height: 1.8rem;
      font-weight: 500;
      max-width: 620px;
      @media (max-width: 1600px) {
        padding-left: 40px;
      }
      @media (max-width: 992px) {
        padding: 0;
        font-size: 1rem;
        max-width: 100%;
      }
      @media (max-width: 568px) {
        font-size: 0.875rem;
        line-height: 1.375rem;
      }
    }
  }
}
