.article-main {
  padding: 100px 0;
  &__container {
    display: flex;
    justify-content: space-between;
    &.container {
      padding-right: 51px;
      @media (max-width: 992px) {
        padding: 50px 15px;
      }
    }
    @media (max-width: 992px) {
      flex-direction: column;
    }
  }
  &__content-wrap {
    width: 58%;
    height: 861px;
    padding-right: 74px;
    margin-top: -34px;
    overflow-y: scroll;
    @media (max-width: 992px) {
      height: initial;
      overflow: initial;
      max-width: initial;
      padding-right: 0;
      margin-top: 0;
      width: 100%;
    }
  }
  &__text {
    font-family: var(--secondary-font-2), Arial, Helvetica, sans-serif;
    font-size: 1.125rem;
    line-height: 2.25rem;
    &:first-child {
      margin-top: 10px;
      &::first-letter {
        font-family: var(--secondary-font-2), Arial, Helvetica, sans-serif;
        font-size: 4.5rem;
        font-weight: 600;
        margin-right: 5px;
        @media (max-width: 576px) {
          font-size: 48px;
        }
      }
      @media (max-width: 992px) {
        margin-top: 30px;
      }
    }
    &:not(:last-child) {
      margin-bottom: 60px;
      @media (max-width: 768px) {
        margin-bottom: 30px;
      }
    }
    @media (max-width: 576px) {
      font-size: 14px;
    }
  }
  &__title {
    font-family: var(--default-font);
    text-transform: initial;
    font-size: 1.875rem;
    font-weight: 700;
    line-height: 2.313rem;
    margin-bottom: 25px;
    @media (max-width: 576px) {
      font-size: 30px;
      margin-bottom: 10px;
    }
  }
  &__quote {
    font-size: 1.875rem;
    font-weight: 600;
    line-height: 2.625rem;
    margin-bottom: 60px;
    @media (max-width: 576px) {
      font-size: 24px;
    }
  }
  &__img-wrap {
    overflow: visible;
    display: flex;
    min-width: calc(50% - 160px);
    justify-content: flex-end;
    height: 861px;
    margin: 0 160px 0 -22px;
    img {
      height: 100%;
      width: 750px;
      object-fit: cover;
      @media (max-width: 992px) {
        width: 100%;
      }
    }
    @media (max-width: 1200px) {
      margin-right: 80px;
      min-width: calc(50% - 80px);
    }
    @media (max-width: 992px) {
      max-width: initial;
      margin: 0;
    }
    @media (max-width: 576px) {
      height: 347px;
    }
  }
  img {
    margin-bottom: 10px;
  }
  ::-webkit-scrollbar {
    width: 15px;
    border: 0.5px solid #9899a0;
    border-radius: 16px;
  }
  ::-webkit-scrollbar-track {
    padding: 3px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: var(--color-primary);
    border-radius: 16px;
    border: 3px solid transparent;
    background-clip: content-box;
  }
  @media (max-width: 768px) {
    padding: 75px 0;
  }
  @media (max-width: 576px) {
    padding: 0;
  }
}
