.custom-select {
  position: relative;
  width: 100%;
  &__hide {
    display: none;
  }
  &__wrap {
    position: absolute;
    left: 0;
    right: 0;
  }
  &__option {
    padding: 10px 15px;
    background-color: white;
    border: 1px solid #9d9d9d;
    font-family: var(--secondary-font-2);
    font-size: 1rem;
    font-weight: 300;
    line-height: 26px;
    cursor: default;
    &:not(:last-child) {
      border-bottom: none;
    }
    &:first-child {
      border-top: none;
    }
  }
  &__selected {
    padding: 9px 15px;
    border: 1px solid #9d9d9d;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: var(--secondary-font-2);
    font-size: 1rem;
    font-style: normal;
    font-weight: 300;
    line-height: 26px;
    cursor: default;
  }
  &__span {
    display: block;
    height: 10px;
    width: 10px;
    border: 2px solid black;
    transform: rotate(45deg);
    border-top: none;
    border-left: none;
    &_opened {
      border: 2px solid black;
      border-right: none;
      border-bottom: none;
      margin-top: 5px;
    }
  }
}
.custom-select__selected.opened {
  background-color: var(--color-secondary-1);
}
