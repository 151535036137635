.article-hero {
  border-bottom: var(--border-style);
  &__container {
    padding: 60px 15px;
    max-width: 1390px;
    margin: 0 auto;
    &.container {
      overflow: visible;
    }
    @media (max-width: 768px) {
      padding: 45px 15px;
    }
    @media (max-width: 576px) {
      padding: 40px 15px;
    }
  }
  &__text {
    text-align: center;
    @media (max-width: 768px) {
      font-size: 35px;
      line-height: 45px;
    }
    @media (max-width: 576px) {
      font-size: 24px;
      line-height: 34px;
    }
  }
}
