.media {
  border-bottom: var(--border-style);
  &__container {
    padding: 150px 0;
    max-width: var(--container-width);
    width: 100%;
    margin: 0 auto;
    @media (max-width: 576px) {
      padding: 50px 0;
    }
  }
  &__title {
    margin-bottom: 73px;
    @media (max-width: 992px) {
      margin-bottom: 40px;
    }
    @media (max-width: 576px) {
      margin-bottom: 30px;
    }
  }
  &__arrows {
    display: flex;
  }
  .keen-slider {
    display: flex;
    margin-bottom: 50px;
    &__img {
      width: 100%;
    }
    @media (max-width: 992px) {
      margin-bottom: 40px;
    }
    @media (max-width: 576px) {
      margin-bottom: 20px;
    }
  }
  .media-slide {
    &__header {
      background: var(--color-primary);
      padding: 49px 290px 49px 70px;
      img {
        width: 290px;
        @media (max-width: 576px) {
          width: 142px;
          height: unset;
        }
      }
      @media (max-width: 992px) {
        padding: 30px 150px 30px 45px;
      }
      @media (max-width: 576px) {
        padding: 24px 116px 24px 30px;
      }
    }
    &__main {
      background: var(--color-secondary-1);
      padding: 42px 70px 70px;
      p {
        padding-bottom: 20px;
        font-size: 1.125rem;
        line-height: 1.813rem;
        @media (max-width: 576px) {
          font-size: 0.875rem;
          line-height: 160%;
          padding-bottom: 10px;
        }
      }
      button {
        font-size: 1.125rem;
        @media (max-width: 576px) {
          font-size: 0.875rem;
          line-height: 160%;
        }
      }
      @media (max-width: 992px) {
        padding: 30px 45px 60px;
      }
      @media (max-width: 576px) {
        padding: 30px;
      }
    }
  }
  .custom-arrow_left {
    margin-right: 40px;
    @media (max-width: 576px) {
      margin-right: 20px;
    }
  }
  .dot {
    border-radius: 50%;
    background: #dddddd;
    border: none;
    display: block;
    height: 18px;
    width: 18px;
    cursor: pointer;
    &.active {
      background: var(--color-primary);
    }
    &:not(:last-child) {
      margin-right: 10px;
      @media (max-width: 576px) {
        margin-right: 5px;
      }
    }
    @media (max-width: 576px) {
      height: 10px;
      width: 10px;
    }
  }
  .dots {
    display: flex;
    @media (max-width: 576px) {
      display: none;
    }
  }
  &__controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
