.clients-say {
  max-width: 1920px;
  margin: 0 auto;
  padding: 150px 0;
  overflow: hidden;
  border-bottom: var(--border-style);
  &__info {
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
  }
  &__title {
    line-height: 3rem;
    letter-spacing: -1px;
  }
  &__quotes {
    padding-top: 95px;
    padding-left: 10px;
  }
  &__text {
    max-width: 753px;
  }
  &__arrows {
    display: flex;
    padding-top: 50px;
    .custom-arrow_left {
      margin-right: 40px;
    }
  }
  &__slider {
    overflow: visible !important;
    padding-top: 55px;
    margin: 0 17px;
  }
  &__slide {
    width: 200px;
  }

  @media (max-width: 1600px) {
    &__text {
      max-width: 600px;
    }
  }

  @media (max-width: 1200px) {
    &__text {
      max-width: 500px;
    }
  }

  @media (max-width: 992px) {
    padding: 42px 0 48px;
    &__info {
      padding: 0;
      flex-wrap: wrap;
    }
    &__title {
      padding-top: 7px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
      line-height: 2.2rem;
      letter-spacing: 0;
    }
    &__quotes {
      padding: 4px 9px 0 0;
      height: 63px;
    }
    &__text {
      padding-top: 18px;
      max-width: 100%;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0;
      text-align: left;
    }
    &__arrows {
      padding-top: 30px;
      .custom-arrow_left {
        margin-left: auto;
        margin-right: 20px;
      }
    }
    &__slider {
      margin: 0;
      padding-top: 20px;
      overflow: hidden !important;
    }
    &__slide {
      iframe {
        width: calc(100%);
        height: calc(100vw / 1.8);
      }
    }
  }
}
