.blog-hero {
  &__container {
    padding: 110px 0 104px;
    position: relative;
    &.container {
      overflow-x: hidden;
    }
    @media (max-width: 992px) {
      padding-bottom: 15px;
    }
    @media (max-width: 768px) {
      padding: 70px 0 15px;
    }
    @media (max-width: 576px) {
      padding: 40px 0 15px;
    }
  }
  &__title {
    margin-bottom: 124px;
    letter-spacing: -2px;
    @media (max-width: 768px) {
      margin-bottom: 80px;
    }
    @media (max-width: 576px) {
      margin-bottom: 40px;
      font-size: 1.5rem;
      letter-spacing: 0;
    }
  }
  &__article {
    width: 50%;
    @media (max-width: 1200px) {
      position: static;
      width: 60%;
    }
    @media (max-width: 992px) {
      width: 100%;
    }
  }
  .hero-article {
    overflow: hidden;
    @media (max-width: 992px) {
      display: flex;
      flex-direction: column-reverse;
    }
    &__content {
      padding: 60px 0 0;
      border-radius: 0 0 48px 0;
      background: var(--color-secondary-1);
      @media (max-width: 768px) {
        padding: 40px 0 0;
      }
      @media (max-width: 576px) {
        padding: 30px 0 0;
      }
    }
    &__date {
      font-size: 1.125rem;
      padding-left: 70px;
      line-height: 1.813rem;
      color: black;
      margin-bottom: 20px;
      @media (max-width: 768px) {
        padding-left: 40px;
      }
      @media (max-width: 576px) {
        padding-left: 31px;
        font-size: 0.875rem;
        margin-bottom: 10px;
      }
    }
    &__title {
      padding-left: 70px;
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 1.938rem;
      margin-bottom: 20px;
      @media (max-width: 768px) {
        padding-left: 40px;
      }
      @media (max-width: 576px) {
        padding-left: 31px;
        font-size: 1.25rem;
        margin-bottom: 10px;
      }
    }
    &__text {
      padding-left: 70px;
      font-size: 1.125rem;
      line-height: 1.813rem;
      margin-bottom: 26px;
      padding-right: 70px;
      @media (max-width: 768px) {
        padding-left: 40px;
      }
      @media (max-width: 576px) {
        padding-left: 31px;
        font-size: 0.875rem;
        margin-bottom: 10px;
      }
    }
    &__more-btn {
      background: transparent;
      outline: none;
      padding-left: 70px;
      border: none;
      font-family: var(--default-font);
      color: var(--color-primary);
      text-decoration: underline;
      font-size: 1.125rem;
      font-weight: 700;
      line-height: 1.438rem;
      margin-bottom: 31px;
      @media (max-width: 768px) {
        padding-left: 40px;
      }
      @media (max-width: 576px) {
        padding-left: 31px;
        font-size: 0.875rem;
      }
    }
    &__img {
      position: absolute;
      right: 0;
      bottom: 8%;
      z-index: -1;
      width: 1140px;
      height: 671px;
      object-fit: cover;
      @media (max-width: 1400px) {
        width: 1000px;
        height: 600px;
      }
      @media (max-width: 992px) {
        position: static;
      }
      @media (max-width: 576px) {
        height: 177px;
        width: 100%;
      }
    }
    &__footer {
      display: flex;
      justify-content: flex-end;
    }
    &__control-wrap {
      background-color: var(--color-primary);
      border-radius: 48px 0;
      max-width: 490px;
      width: 100%;
      display: flex;
      padding: 29px 46px;
      @media (max-width: 992px) {
        max-width: 1000px;
        display: flex;
        justify-content: space-between;
        padding: 29px 70px;
      }
      @media (max-width: 768px) {
        padding: 29px 40px;
      }
      @media (max-width: 576px) {
        padding: 22px 31px;
      }
    }
    &__controls {
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.438rem;
      color: #ffffff;
      &_span {
        font-weight: 400;
      }
      &:not(:last-child) {
        margin-right: 42px;
        @media (max-width: 992px) {
          margin-right: 0;
        }
      }
      @media (max-width: 576px) {
        font-size: 0.75rem;
      }
    }
  }
}
