.other-articles {
  background: var(--color-secondary-1);
  border-bottom: var(--border-style);
  &__container {
    padding: 156px 15px;
    max-width: 1594px;
    margin: 0 auto;
    h2 {
      margin-bottom: 26px;
      @media (max-width: 768px) {
        padding: 0 15px;
      }
    }
    @media (max-width: 768px) {
      padding: 100px 0;
    }
    @media (max-width: 576px) {
      padding: 50px 0;
    }
  }
  &__article-wrap {
    display: flex;
  }
  .article-item {
    padding-bottom: 0;
    align-self: stretch;
    .post-article__content {
      padding-top: 43px;
      .post-article__date {
        padding-left: 40px;
      }
      .post-article__title {
        padding-left: 40px;
      }
      .post-article__text {
        padding-left: 40px;
        font-size: 1rem;
        line-height: 26px;
      }
      .button-read-more {
        padding-left: 40px;
      }
    }
  }
  .post-article__content {
    background-color: #fff;
  }
  .keen-slider {
    display: flex;
    @media (max-width: 992px) {
      margin: 0;
    }
  }
  .dot {
    border-radius: 50%;
    background: #dddddd;
    border: none;
    display: block;
    height: 18px;
    width: 18px;
    cursor: pointer;
    padding: 0;
    box-sizing: border-box;
    &.active {
      background: var(--color-primary);
    }
    &:not(:last-child) {
      margin-right: 10px;
      @media (max-width: 576px) {
        margin-right: 5px;
      }
    }
    @media (max-width: 576px) {
      height: 10px;
      width: 10px;
    }
  }
  .dots {
    display: flex;
  }
  &__slider-control {
    display: none;
    @media (max-width: 1200px) {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      align-items: center;
      padding: 0 15px;
    }
  }
  .custom-arrow_left {
    margin-right: 15px;
    @media (max-width: 768px) {
      margin-right: 20px;
    }
  }
  .associations__arrows {
    display: flex;
  }
}
