.footer {
  .footer-nav {
    border-top: var(--border-style);
    border-bottom: var(--border-style);
    &__wrap {
      display: flex;
      align-items: center;
    }
    &__logo {
      height: 159px;
      display: flex;
      align-items: center;
      border-right: var(--border-style);
      padding-right: 130px;
      @media (max-width: 992px) {
        width: 47%;
        height: unset;
        padding: 20px 0;
      }
      @media (max-width: 576px) {
        padding: 15px 0;
      }
      img {
        @media (max-width: 567px) {
          height: 40px;
          width: auto;
        }
      }
    }
    &__list {
      padding-left: 160px;
      width: 82.8%;
      display: flex;
      justify-content: flex-end;
      @media (max-width: 1050px) {
        padding-left: 50px;
      }
      @media (max-width: 992px) {
        display: none;
      }
    }
    @media (max-width: 992px) {
      border-bottom: var(--border-style);
    }
  }
  .footer-info {
    background: #efefef;
    border-bottom: var(--border-style);
    padding: 60px 0;
    &__content {
      display: flex;
      @media (max-width: 992px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 30px;
      }
    }
    &__item {
      &:not(:last-child) {
        padding-right: 195px;
        @media (max-width: 992px) {
          padding-right: 0;
          padding-bottom: 20px;
        }
      }
      @media (max-width: 992px) {
        padding-right: 0;
        text-align: center;
      }
    }
    &__list {
      li {
        font-size: 1.125rem;
        line-height: 160%;
        color: #000000;
        @media (max-width: 567px) {
          font-size: 12px;
        }
      }
    }
    &__title {
      font-size: 1.313rem;
      font-weight: 600;
      line-height: 34px;
      letter-spacing: 0;
      font-family: var(--default-font);
      margin-bottom: 12px;
      @media (max-width: 567px) {
        font-size: 14px;
      }
    }
    &__container {
      display: flex;
      justify-content: space-between;
      @media (max-width: 1050px) {
        flex-direction: column;
      }
    }
    @media (max-width: 992px) {
      padding: 30px 0;
    }
  }
  .footer-media {
    &__list {
      display: flex;
      padding: 0 45px;
      li {
        height: 55px;
        width: 55px;
        border: 0.5px solid #000000;
        background-color: white;
        border-radius: 50%;
        display: flex;
        cursor: pointer;
        justify-content: center;
        align-items: center;
        &:hover {
          background-color: var(--color-primary);
          border-color: var(--color-primary);
          svg {
            fill: white;
          }
        }
        svg {
          fill: black;
        }
        @media (max-width: 567px) {
          height: 40px;
          width: 40px;
        }
      }
      li:not(:last-child) {
        margin-right: 20px;
      }
      img {
        height: 17.314817428588867px;
        width: 17.314817428588867px;
      }
      @media (max-width: 992px) {
        justify-content: center;
      }
    }
  }
  .footer__copyright {
    font-size: 0.875rem;
    line-height: 130%;
    padding: 30px 0;
    @media (max-width: 992px) {
      text-align: center;
    }
    @media (max-width: 576px) {
      padding: 15px 0;
    }
  }
  .footer-nav-mobile {
    &__list {
      display: none;
      @media (max-width: 992px) {
        display: block;
        border-bottom: var(--border-style);
        .nav {
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .nav-element {
            margin-right: 0;
            &:not(:last-child) {
              margin-bottom: 20px;
            }
          }
        }
      }
    }
  }
}
