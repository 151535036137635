.other-projects {
  padding: 150px 0;
  @media (max-width: 978px) {
    padding: 75px 0;
  }
  @media (max-width: 576px) {
    padding: 50px 0;
  }
  background: var(--color-secondary-1);
  border-bottom: var(--border-style);
  &__title {
    font-family: var(--secondary-font);
    font-weight: 700;
    text-transform: uppercase;
    font-size: 2.25rem;
    line-height: 3.186rem;
    margin-bottom: 60px;
    @media (max-width: 576px) {
      font-size: 1.5rem;
      line-height: 2.124rem;
      margin-bottom: 20px;
    }
  }
  &__navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .project-item {
    margin-bottom: 50px;
    @media (max-width: 978px) {
      margin: 0 auto 50px;
    }
    @media (max-width: 576px) {
      margin-bottom: 20px;
    }
    &__name {
      background: #ffffff;
    }
  }
  .dots {
    display: flex;
    @media (max-width: 576px) {
      bottom: 20px;
    }
    .dot {
      border-radius: 50%;
      background: #dddddd;
      border: none;
      display: block;
      height: 18px;
      width: 18px;
      padding: 0;
      cursor: pointer;
      @media (max-width: 576px) {
        height: 9px;
        width: 9px;
      }
      &.active {
        background: var(--color-primary);
      }
      &:not(:last-child) {
        margin-right: 10px;
        @media (max-width: 576px) {
          margin-right: 5px;
        }
      }
    }
  }
  .custom-arrow_left {
    margin-right: 40px;
    @media (max-width: 576px) {
      margin-right: 20px;
    }
  }
  &__arrows {
    display: flex;
  }
}
