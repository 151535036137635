.projects-main {
  padding: 150px 0;
  border-bottom: var(--border-style);
  @media (max-width: 1050px) {
    padding: 75px 0;
  }
  @media (max-width: 568px) {
    padding: 50px 0;
  }
  .custom-button {
    margin: 40px auto 0;
    @media (max-width: 568px) {
      margin: 10px auto 0;
    }
  }
}
.project-items {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 1050px) {
    justify-content: center;
  }
}
