.testimonials-filter {
  padding-right: 3px;
  padding-top: 10px;
  &__button {
    color: #000000;
    cursor: pointer;
    width: 230px;
    height: 78px;
    margin-left: 30px;
    background-color: var(--color-secondary-1);
    border: none;
    outline: none;
    font-family: var(--default-font);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 29px;
    &_selected {
      background-color: #ffffff;
      color: var(--color-primary);
      outline: 2px solid var(--color-primary);
      outline-offset: -2px;
    }
  }
  @media (max-width: 1440px) {
    &__button {
      width: 150px;
      height: 50px;
      margin-left: 20px;
    }
  }
  @media (max-width: 992px) {
    &__button {
      width: 115px;
      height: 40px;
      font-size: 0.875rem;
    }
    &__button:first-child {
      margin-left: 0;
    }
  }
  @media (max-width: 576px) {
    padding: 30px 0 0;
    display: flex;
    width: 100%;
    &__button {
      margin-left: 0;
      margin-right: 12px;
      width: 88px;
      height: 30px;
      display: flex;
      flex-shrink: 0;
      justify-content: center;
      align-items: center;
      font-family: var(--default-font);
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 500;
      line-height: 19px;
      letter-spacing: 0;
      text-align: left;
    }
    &__button:first-child {
      margin-left: 0;
    }
  }
}
