.testimonials-cards {
  padding: 70px 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  &:after {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    width: 100vw;
    border-bottom: var(--border-style);
  }
  &__button-container {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-shrink: 0;
    padding: 70px 0 80px;
    @media (max-width: 576px) {
      padding: 21px 0 12px;
    }
  }
  @media (max-width: 576px) {
    padding: 39px 0;
  }
}
