.info {
  border-bottom: var(--border-style);
  &__container {
    display: flex;
    align-items: center;
    height: 266px;
    @media (max-width: 992px) {
      flex-direction: column;
      height: 100%;
      padding: 0 !important;
    }
  }
  &__title-wrap {
    width: 44.5%;
    @media (max-width: 992px) {
      width: 100vw;
      border-bottom: var(--border-style);
      padding: 30px 15px;
    }
  }
  &__title {
    &_span {
      display: block;
      font-size: 7.5rem;
      margin: 10px 98px 10px -10px;
      color: white;
      text-shadow: 0 0 2px var(--color-primary), 0 0 1px var(--color-primary);
      @media (max-width: var(--container-width)) {
        font-size: 6.25rem;
        margin: 10px 70px 10px -10px;
      }
      @media (max-width: 1300px) {
        font-size: 5rem;
      }
      @media (max-width: 1000px) {
        margin: 0 auto 30px;
      }
      @media (max-width: 992px) {
        margin-bottom: 0;
      }
      @media (max-width: 568px) {
        font-size: 3rem;
        margin-top: -20px;
      }
    }
    @media (max-width: 1300px) {
      font-size: 2.5rem;
    }
    @media (max-width: 568px) {
      font-size: 1.5rem;
    }
  }
  &__item-list {
    margin-left: 130px;
    @media (max-width: 1300px) {
      margin-left: 70px;
    }
    @media (max-width: 992px) {
      margin-left: 0;
      padding: 50px 15px;
      align-self: flex-start;
      position: relative;
      &::after {
        content: "";
        height: 100%;
        position: absolute;
        width: 100vw;
        background: var(--color-secondary-1);
        top: 0;
        left: 0;
        right: 0;
        z-index: -1;
      }
    }
  }
  &__item {
    display: flex;
    justify-content: space-between;
    & p {
      color: var(--color-primary);
      font-weight: 700;
      font-size: 1.125rem;
      line-height: 1.3rem;
      @media (max-width: 568px) {
        font-size: 0.75rem;
      }
    }
    div {
      width: 33.3333%;
      p:first-child {
        font-size: 2.25rem;
        line-height: 1rem;
        padding-bottom: 25px;
      }
      &:not(:last-child) {
        padding-right: 130px;
        @media (max-width: var(--container-width)) {
          padding-right: 70px;
        }
        @media (max-width: 568px) {
          padding-right: 18px;
        }
      }
    }
  }
  &__span {
    font-size: 0;
    border-left: var(--border-style);
    position: relative;
    height: 100%;
    &::before {
      background: var(--color-secondary-1);
      content: "";
      display: block;
      width: 100vw;
      position: absolute;
      left: 0;
      height: 100%;
      z-index: -1;
    }
    @media (max-width: 992px) {
      display: none;
    }
  }
}
