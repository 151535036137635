.about-help {
  border-bottom: var(--border-style);
  &__container {
    padding: 150px 0;
    @media (max-width: 992px) {
      padding: 50px 0;
    }
  }
  &__title {
    margin-bottom: 45px;
    @media (max-width: 576px) {
      font-size: 24px;
      margin-bottom: 15px;
    }
    @media (max-width: 992px) {
      margin-bottom: 25px;
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
  }
  &__item {
    width: 33.33333%;
    padding: 15px;
    @media (max-width: 1100px) {
      width: 50%;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  &__item-wrap {
    height: 256px;
    background: var(--color-secondary-1);
    border-radius: 0 0 50px 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    h3 {
      font-size: var(--size-text);
      font-weight: 600;
      line-height: 22px;
      max-width: 300px;
      text-align: center;
      @media (max-width: 576px) {
        font-size: 14px;
      }
    }
    img {
      margin-bottom: 44px;
      height: 80px;
      width: 80px;
      @media (max-width: 576px) {
        margin-bottom: 20px;
      }
    }
  }
}
