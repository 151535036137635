.blog-list {
  border-bottom: var(--border-style);
  &__container {
    padding-bottom: 150px;
    @media (max-width: 768px) {
      padding-bottom: 70px;
    }
    @media (max-width: 576px) {
      padding-bottom: 50px;
    }
  }
  &__row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
    margin-bottom: 65px;
    @media (max-width: 576px) {
      margin-bottom: 30px;
    }
    .article-item:not(:nth-child(-n + 2)) {
      width: 33.333333%;
      .post-article {
        &__content {
          padding: 40px 0 0;
        }
        &__date {
          padding-left: 43px;
        }
        &__title {
          padding-left: 43px;
        }
        &__text {
          padding: 0 43px;
          font-size: 16px;
          line-height: 160%;
        }
        &__more-btn {
          padding-left: 43px;
          margin-bottom: 37px;
        }
      }
      @media (max-width: 1200px) {
        width: 50%;
      }
      @media (max-width: 768px) {
        width: 100%;
      }
    }
    .article-item:nth-child(-n + 2) {
      width: 50%;
      img.article-item__img {
        height: 463px;
        @media (max-width: 576px) {
          height: 177px;
        }
      }
      @media (max-width: 768px) {
        width: 100%;
      }
    }
    .article-item {
      @media (max-width: 768px) {
        padding: 15px;
      }
    }
  }
  .custom-button {
    margin: 0 auto;
  }
}
