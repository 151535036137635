.header {
  border-bottom: var(--border-style);
  z-index: 3;
  &__wrap {
    display: flex;
    justify-content: space-between;
    width: 100%;
    outline: none;
  }
  &__mobile-close {
    display: none;
    outline: none;
    background: transparent;
    border: none;
    height: 63px;
    width: 63px;
    position: absolute;
    right: 20px;
    top: 27px;
    img {
      height: 25px;
    }
    @media (max-width: 992px) {
      display: block;
    }
  }
  &__logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 25%;
    position: relative;
    border-right: var(--border-style);
    &::after {
      content: "";
      display: block;
      background: #efefef;
      height: 100%;
      position: absolute;
      width: 100vw;
      right: 0;
      z-index: -1;
    }
    @media (max-width: 1020px) {
      width: 30%;
    }
    @media (max-width: 992px) {
      width: 40%;
      padding: 20px 0;
    }
    @media (max-width: 568px) {
      width: 50%;
      padding: 20px 0;
    }
  }
  &__logo-span {
    font-size: 0;
    border-right: var(--border-style);
    position: relative;
    &::before {
      background: #efefef;
      content: "";
      display: block;
      width: 100vw;
      position: absolute;
      height: 100%;
      right: 0;
      z-index: -1;
    }
  }
  &__nav {
    margin-left: 10em;
    width: 75%;
    @media (max-width: 1020px) {
      width: 70%;
      margin-left: 3em;
    }
    @media (max-width: 992px) {
      display: none;
    }
  }
  .header-logo {
    height: 98.1px;

    @media (max-width: 1595px) {
      height: 80px;
    }
    @media (max-width: 567px) {
      height: 65px;
      max-width: unset;
      width: 100%;
    }
    @media (max-width: 450px) {
      height: 50px;
    }
  }
  &__mobile-menu-btn {
    display: none;
    border: none;
    outline: none;
    background: transparent;
    img {
      height: 25px;
      @media (max-width: 567px) {
        height: 20px;
      }
    }

    @media (max-width: 992px) {
      display: block;
    }
  }
  &__mobile-menu-list {
    display: none;
    .nav {
      @media (max-width: 992px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 90px;
        border-bottom: var(--border-style);
        border-color: var(--color-secondary-2);
        .nav-element {
          margin-right: 0;
        }
        .nav-element {
          &:not(:last-child) {
            margin-bottom: 40px;
          }
        }
      }
    }
    @media (max-width: 992px) {
      &.active {
        display: block;
        position: absolute;
        background: white;
        left: 0;
        right: 0;
        top: 0;
        z-index: 1;
      }
    }
  }
}
