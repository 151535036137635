.project-gallery {
  padding: 160px 0;
  border-bottom: var(--border-style);
  @media (max-width: 576px) {
    padding: 55px 0;
  }
  &__title {
    letter-spacing: -1px;
    text-align: right;
    padding-right: 15px;
    @media (max-width: 992px) {
      text-align: left;
      letter-spacing: 0;
    }
  }
  &__slider-container {
    display: flex;
    flex-direction: column-reverse;
    padding: 50px 18px 0;
    @media (max-width: 992px) {
      padding: 50px 0 0;
    }
    @media (max-width: 576px) {
      padding: 24px 0;
    }
  }

  &__slider {
    display: flex;
    @media (max-width: 992px) {
      flex-wrap: wrap;
    }
  }

  &__picture {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 703px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    @media (max-width: 992px) {
      img {
        min-height: calc(100vw - 200px);
        width: 100vw;
      }
    }
    @media (max-width: 576px) {
      img {
        height: calc(100vw - 32px);
        width: 100vw;
      }
    }
  }

  &__project_name {
    position: absolute;
    left: 0;
    top: 0;
    min-width: 360px;
    padding: 30px;
    border-bottom-right-radius: 55px;
    color: #ffffff;
    background-color: var(--color-primary);
    font-size: 1.3125rem;
    font-weight: 600;
    letter-spacing: 0;
    text-align: left;
    text-transform: uppercase;
    @media (max-width: 992px) {
      min-width: 280px;
      padding: 20px 25px;
      font-size: 1rem;
      font-weight: 700;
      line-height: 15px;
    }
    @media (max-width: 576px) {
      min-width: 237px;
      padding: 12px 16px;
      font-size: 0.8rem;
      font-weight: 700;
      line-height: 15px;
    }
  }

  &__arrows {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: column;
    min-width: 17%;
    button {
      height: 155px;
      width: 155px;
      img {
        width: 100px;
      }
      @media (max-width: var(--container-width)) {
        width: 100px;
        height: 100px;
        img {
          width: 75px;
        }
      }
      @media (max-width: 1366px) {
        width: 75px;
        height: 75px;
        img {
          width: 50px;
        }
      }
      @media (max-width: 576px) {
        width: 40px;
        height: 40px;
        img {
          width: 26px;
        }
      }
    }
    .custom-arrow_left {
      margin-bottom: 30px;
    }
    @media (max-width: 992px) {
      flex-direction: row;
      width: 100%;
      margin-top: 20px;
      .custom-arrow_left {
        margin-bottom: 0;
        margin-right: 20px;
      }
      p {
        align-self: center;
        margin-right: auto;
        font-size: 1.2rem;
        @media (max-width: 576px) {
          font-size: 0.8rem;
        }
      }
    }
  }

  &__thumbnail {
    padding: 15px 0 31px;
    overflow: visible !important;
    min-height: 71px;
    @media (max-width: 568px) {
      padding-bottom: 15px;
      margin: 0 5px;
    }
  }

  &__active {
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 5px var(--color-primary);
  }

  &__thumbnail_slide {
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 130px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &:hover {
      cursor: pointer;
    }
    @media (max-width: 576px) {
      img {
        max-height: 71px;
      }
    }
  }
}
